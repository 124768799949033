import { Sha256 as BrowserSha256 } from '@aws-crypto/sha256-browser'

// sha256
export const sha256 = async (data: string): Promise<string> => {
  const hash = new BrowserSha256()
  hash.update(data, 'utf8')
  const buffer = await hash.digest()

  return bufferToHex(buffer)
}

export const bufferToHex = (buffer: Uint8Array): string => {
  return Array.from(buffer)
    .map((b) => b.toString(16).padStart(2, '0'))
    .join('')
}

// base64
const percentToByte = (p: string): string => {
  return String.fromCharCode(parseInt(p.slice(1), 16))
}

export const encodeBase64 = (data: string): string => {
  return window.btoa(encodeURIComponent(data).replace(/%[0-9A-F]{2}/g, percentToByte))
}

const byteToPercent = (b: string): string => {
  return `%${`00${b.charCodeAt(0).toString(16)}`.slice(-2)}`
}

export const decodeBase64 = (data: string): string => {
  return decodeURIComponent(Array.from(window.atob(data), byteToPercent).join(''))
}

// others
export const randomString = (length: number): string => {
  const array = new Uint8Array(Math.ceil(length / 2))
  window.crypto.getRandomValues(array)

  return bufferToHex(array).substring(0, length)
}
